import React, { useEffect } from "react";

export default function PBNewApp() {
  useEffect(() => {
    // Redirect User to One link
    window.location.href = "https://onelink.to/cghkgz";
  }, []);

  return <div></div>;
}
